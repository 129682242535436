import {
  makeStyles,
  Typography,
  FormHelperText,
  Button,
  MenuItem,
} from "@material-ui/core";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaAutocomplete from "../core/input/CotroliaAutocomplete";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },

  input: {
    "& > .MuiFilledInput-root": {
      borderRadius: "10px",
    },
    "& label": {
      color: "#276728",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#f2f3f0",
    },
    "&::hover": {
      backgroundColor: "#f2f3f0",
    },
    "& .MuiFilledInput-underline:before": {
      display: "none",
    },
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepCar = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [carBrandError, setCarBrandError] = useState(emptyError);
  const [carModelError, setCarModelError] = useState(emptyError);
  const [frameError, setFrameError] = useState(emptyError);
  const [registrationError, setRegistrationError] = useState(emptyError);
  const [distanceError, setDistanceError] = useState(emptyError);
  const [customerEffectError, setCustomerEffectError] = useState(emptyError);
  const [diagnosticError, setDiagnosticError] = useState(emptyError);
  const [partsReplacedError, setPartsReplacedError] = useState(emptyError);
  const [modelDisabled, setModelDisabled] = useState(!values.car.carBrand?.id);

  const handleClick = () => {
    let hasError = false;

    if (values.car.frame === "") {
      setFrameError({
        error: true,
        msg: "RequiredFieldFrameError",
      });
      hasError = true;
    }

    if (values.car.distance === "") {
      setDistanceError({
        error: true,
        msg: "RequiredFieldDistanceError",
      });
      hasError = true;
    }

    if (values.car.registration === "") {
      setRegistrationError({
        error: true,
        msg: "RequiredFieldRegistrationError",
      });
      hasError = true;
    }

    if (values.car.customer_effect === "") {
      setCustomerEffectError({
        error: true,
        msg: "RequiredFieldCustomerEffectError",
      });
      hasError = true;
    }

    if (values.car.diagnostic === "") {
      setDiagnosticError({
        error: true,
        msg: "RequiredFieldDiagnosticError",
      });
      hasError = true;
    }

    if (values.car.parts_replaced === "") {
      setPartsReplacedError({
        error: true,
        msg: "RequiredFieldPartsReplacedError",
      });
      hasError = true;
    }

    if (!values.car.carBrand?.id) {
      setCarBrandError({
        error: true,
        msg: "RequiredFieldCarBrandError",
      });
      hasError = true;
    }

    if (!values.car.carModel?.id) {
      setCarModelError({
        error: true,
        msg: "RequiredFieldCarModelError",
      });
      hasError = true;
    }

    if (!hasError) {
      nextStep();
    }
  };
  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("YourBrokenCar")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("YourBrokenCarDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Frame */}
          <CotroliaFormControl error={frameError.error}>
            <CotroliaTextField
              id="frame"
              label={t("Frame")}
              inputProps={{ maxLength: 17 }}
              maxLength={5}
              value={values.car.frame}
              onChange={(e) => {
                if (e.target.value !== "" && e.target.value.length === 17) {
                  setFrameError(emptyError);
                } else {
                  setFrameError({
                    error: false,
                    msg: "RequiredFieldFrameError",
                  });
                }
                updateValue("car", "frame", e.target.value);
              }}
              required
              rows={1}
              error={frameError.error}
            />
          </CotroliaFormControl>

          {/* Car Brand */}
          <CotroliaFormControl error={carBrandError.error}>
            <CotroliaAutocomplete
              id="brand-car-label"
              label={t("CarBrand")}
              value={values.car.carBrand}
              error={carBrandError.error}
              onChange={(newValue) => {
                setCarBrandError(emptyError);
                updateValue("car", "carBrand", newValue);
                setModelDisabled(!newValue?.id);
                updateValue("car", "carModel", null); // Make model value to empty if the carBrand change
              }}
              getOptions={(searchValue) => {
                const brands = configuration.carBrandModel;
                let options = [];
                Object.keys(brands).forEach((key) => {
                  options.push({ id: key, label: brands[key].label });
                });
                const filteredOptions = options.filter((opt) =>
                  opt.label.toLowerCase().includes(searchValue.toLowerCase())
                );
                return new Promise((resolve) =>
                  resolve(_.sortBy(filteredOptions, (o) => o.label))
                );
              }}
              getOptionLabel={(option) => {
                return !!option?.label ? option.label : "";
              }}
              required
            />
            <FormHelperText variant="outlined">
              {t(carBrandError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Car Model */}
          <CotroliaFormControl error={carModelError.error}>
            <CotroliaAutocomplete
              id="model-car-label"
              label={t("CarModel")}
              value={values.car.carModel}
              error={carModelError.error}
              onChange={(newValue) => {
                setCarModelError(emptyError);
                updateValue("car", "carModel", newValue);
              }}
              getOptions={(searchValue) => {
                let options = [];
                if (!!values.car.carBrand?.id) {
                  const models =
                    configuration.carBrandModel[values.car.carBrand.id].values;
                  Object.keys(models).forEach((key) => {
                    options.push({ id: key, label: models[key] });
                  });
                }
                const filteredOptions = options.filter((opt) =>
                  opt.label.toLowerCase().includes(searchValue.toLowerCase())
                );
                return new Promise((resolve) =>
                  resolve(_.sortBy(filteredOptions, (o) => o.label))
                );
              }}
              disabled={modelDisabled}
              required
              getOptionLabel={(option) => {
                return !!option?.label ? option.label : "";
              }}
            />
            <FormHelperText variant="outlined">
              {t(carModelError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Distance */}
          <CotroliaFormControl error={distanceError.error}>
            <CotroliaTextField
              id="distance"
              label={t("Distance")}
              value={values.car.distance}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDistanceError(emptyError);
                } else {
                  setDistanceError({
                    error: true,
                    msg: "RequiredFieldDistanceError",
                  });
                }
                updateValue("car", "distance", e.target.value);
              }}
              required
              type={"number"}
              error={distanceError.error}
            />
          </CotroliaFormControl>

          {/* Registration */}
          <CotroliaFormControl error={registrationError.error}>
            <CotroliaTextField
              id="registration"
              label={t("Registration")}
              value={values.car.registration}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setRegistrationError(emptyError);
                } else {
                  setRegistrationError({
                    error: true,
                    msg: "RegisterErrorMissingRequiredFields",
                  });
                }

                updateValue("car", "registration", e.target.value);
              }}
              required
              error={registrationError.error}
            />
          </CotroliaFormControl>

          {/* Security code */}
          <CotroliaFormControl>
            <CotroliaTextField
              id="securitycode"
              label={t("SecurityCodeCotrolia")}
              value={values.car.security_code}
              onChange={(e) => {
                updateValue("car", "security_code", e.target.value);
              }}
            />
          </CotroliaFormControl>
        </LeftSide>

        <RightSide>
          {/* Frequency */}
          <CotroliaFormControl variant="filled">
            <CotroliaInputLabel id="frequency-label">
              {t("PartFrequency")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="frequency"
              labelId="frequency-label"
              value={values.part.frequency}
              onChange={(e) => {
                updateValue("part", "frequency", e.target.value);
              }}
            >
              {configuration?.partFrequency &&
                Object.keys(configuration.partFrequency).map((key) => (
                  <MenuItem value={key} key={key}>
                    {configuration.partFrequency[key]}
                  </MenuItem>
                ))}
            </CotroliaSelect>
          </CotroliaFormControl>

          {/* Customer Effect */}
          {configuration.sector === "1" && (
            <CotroliaFormControl error={customerEffectError.error}>
              <CotroliaTextField
                id="customer_effect"
                label={t("CarCustomerEffectCotrolia")}
                value={values.car.customer_effect}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setCustomerEffectError(emptyError);
                  } else {
                    setCustomerEffectError({
                      error: true,
                      msg: "RequiredFieldCustomerEffectError",
                    });
                  }
                  updateValue("car", "customer_effect", e.target.value);
                }}
                required
                multiline
                rows={4}
                error={customerEffectError.error}
              />
            </CotroliaFormControl>
          )}

          {/* Diagnostic */}
          <CotroliaFormControl error={diagnosticError.error}>
            <CotroliaTextField
              id="diagnostic"
              label={t("DiagnosticCotrolia")}
              value={values.car.diagnostic}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDiagnosticError(emptyError);
                } else {
                  setDiagnosticError({
                    error: true,
                    msg: "RequiredFieldDiagnosticError",
                  });
                }
                updateValue("car", "diagnostic", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={diagnosticError.error}
            />
          </CotroliaFormControl>

          {/* Parts Replaced */}
          <CotroliaFormControl error={partsReplacedError.error}>
            <CotroliaTextField
              id="parts-replaced"
              label={t("PartsReplaced")}
              value={values.car.parts_replaced}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setPartsReplacedError(emptyError);
                } else {
                  setPartsReplacedError({
                    error: true,
                    msg: "RequiredFieldPartsReplacedError",
                  });
                }
                updateValue("car", "parts_replaced", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={partsReplacedError.error}
            />
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>
      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepCar;
