import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./context/auth-context";
import reportWebVitals from "./reportWebVitals";
import { Suspense } from "react";
import FullPageLoader from "./components/core/FullPageLoader";
import Corner from "./components/core/Corner";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AppConstant from "./utils/appConstant";
import { ConfigurationProvider } from "./context/configuration-context";
import MatomoTracker from "./components/MatomoTracker";

// Alert configuration
const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: AppConstant.cornerZIndex,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<FullPageLoader />}>
      <AlertProvider template={Corner} {...alertOptions}>
        <ConfigurationProvider>
          <MatomoTracker>
            <AuthProvider>
              <App />
            </AuthProvider>
          </MatomoTracker>
        </ConfigurationProvider>
      </AlertProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
