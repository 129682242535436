import axios from "axios";

class Api {
  constructor() {
    let api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      responseType: "json",
      withCredentials: true,
    });
    api.interceptors.request.use(this.handleRequest);
    api.interceptors.response.use(this.handleSuccess, this.handleError);

    this.api = api;
  }

  handleRequest(request) {
    if (process.env.REACT_APP_ENVIRONMENT !== "development") {
      const localStorageUrl = localStorage.getItem("externalApiUrl");
      if (!!localStorageUrl) {
        request.baseURL = localStorageUrl;
      }
    }
    return request;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Redirect to login
          if (
            ![
              "/",
              "/login",
              "/register",
              "/forget-password",
              "/reset-password",
            ].includes(window.location.pathname)
          ) {
            window.location.href = "/login";
          }
          break;
        default:
          console.error("ERR " + error.response.status);
          break;
      }
    }
    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  async get(path, callback) {
    const response = await this.api.get(path);
    return callback(response.status, response.data);
  }

  async put(path, payload, callback) {
    const response = await this.api.put(path, payload);
    return callback(response.status, response.data);
  }

  async post(path, payload, callback) {
    return this.api
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) => callback(response.status, response.data));
  }

  async delete(path, callback) {
    const response = await this.api.delete(path);
    return callback(response.status, response.data);
  }
}

export default Api;
