import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../core/Header";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useMenu } from "../../context/menu-context";
import CircleButton from "../core/CircleButton";
import { Box, Button, makeStyles } from "@material-ui/core";
import HeaderActions from "../core/HeaderActions";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router";
import Content from "../core/layout/Content";
import NewInterventionForm from "../intervention/NewInterventionForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "../../services/moment";
import { cloneDeep } from "lodash";
import interventionService from "../../services/intervention.service";
import { emptyAddress, formateInterventionData } from "../../utils/model";
import { useAlert } from "react-alert";
import AppConstant from "../../utils/appConstant";
import { useAuth } from "../../context/auth-context";

const useStyle = makeStyles((theme) => ({
  leftBtn: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightBtn: {
    margin: "0px 16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  id: "",
  part: {
    waranty: 2,
    warrantyPrevInter: {
      id: null,
      label: "",
    },
    type: "",
    manufacturer: {
      id: null,
      label: "",
    },
    description: "",
    ref: {
      id: null,
      ref: "",
    },
    refNotVisible: false,
    clientRef: "",
    frequency: "",
    oldpictures: [],
    pictures: [],
  },
  car: {
    frame: "",
    carBrand: {
      id: null,
      label: "",
    },
    carModel: {
      id: null,
      label: "",
    },
    distance: "",
    security_code: "",
    registration: "",
    customer_effect: "",
    diagnostic: "",
    parts_replaced: "",
  },
  shipping: {
    method: AppConstant.shippingMethodPicking,
    pickupDate: moment(),
    company: "",
    informations: "",
    address: {
      ...emptyAddress,
    },
    phone: "",
    closingDates: {
      monday: false,
      saturday: false,
    },
  },
  packaging: {
    partNb: 1,
    instructions: false,
    address: "",
    complementaryPart: false,
  },
  finish: {
    mainContact: "",
    billingContact: "",
    technicalContact: "",
  },
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

function base64ToFile(dataurl, filename) {
  var bstr = atob(dataurl),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename);
}

const NewIntervention = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const menu = useMenu();
  const classes = useStyle();
  const history = useHistory();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [values, setValues] = useState(defaultValues);
  let query = useQuery();
  const idParam = query.get("id");
  const { user, fetchConnectedUser } = useAuth();

  useEffect(() => {
    /**
     * Load values from the result of the saved intervention
     *
     * @param {*} res
     * @param {*} datas
     */
    const formatData = (res, datas) => {
      datas.id = res.id;

      datas.part.waranty =
        res.waranty === "1" ? AppConstant.warrantyYes : AppConstant.warrantyNo;
      datas.part.warrantyPrevInter = res.warrantyPrevInter
        ? res.warrantyPrevInter
        : { id: null, label: "" };
      datas.part.type = res.part.type ? res.part.type : "";
      datas.part.manufacturer = res.part.manufacturer
        ? res.part.manufacturer
        : "";
      datas.part.description = res.description ? res.description : "";
      datas.part.ref = res.part.ref ? res.part.ref : "";
      datas.part.refNotVisible = res.refNotVisible;
      datas.part.clientRef = res.clientRef ? res.clientRef : "";
      datas.part.frequency = res.frequency ? res.frequency : "";

      res.pictures.forEach((picture) => {
        datas.part.pictures.push(base64ToFile(picture.content, picture.name));
      });
      // keep list of previously saved pictures if we delete some during the process
      datas.part.oldpictures = datas.part.pictures;

      // Car
      datas.car.frame = res.car.frame ? res.car.frame : "";
      datas.car.carBrand = res.car.carBrand ? res.car.carBrand : "";
      datas.car.carModel = res.car.carModel ? res.car.carModel : "";
      datas.car.distance = res.car.distance ? res.car.distance : "";
      datas.car.security_code = res.car.security_code
        ? res.car.security_code
        : "";
      datas.car.registration = res.car.registration ? res.car.registration : "";
      //datas.car.year = res.car.year ? res.car.year : "";

      datas.car.customer_effect = res.car.customer_effect
        ? res.car.customer_effect
        : "";
      datas.car.diagnostic = res.car.diagnostic ? res.car.diagnostic : "";
      datas.car.parts_replaced = res.car.parts_replaced
        ? res.car.parts_replaced
        : "";

      datas.shipping.method = !!res.shipping.method
        ? parseInt(res.shipping.method)
        : AppConstant.shippingMethodPicking;
      datas.shipping.pickupDate = res.shipping.pickupDate;
      if (res.shipping.company !== "") {
        datas.shipping.company = res.shipping.company;
      }

      datas.shipping.informations = res.shipping.informations
        ? res.shipping.informations
        : "";

      datas.shipping.address = { ...emptyAddress };
      if (res.shipping.address) {
        datas.shipping.address.fullAddress = res.shipping.address;
      } else {
        datas.shipping.address.city = res.shipping.city;
        datas.shipping.address.country = "";
        datas.shipping.address.countryCode = res.shipping.countryCode;
        datas.shipping.address.zipCode = res.shipping.zip;
        datas.shipping.address.street = res.shipping.addressNumber;
        datas.shipping.address.fullAddress = res.shipping.fullAddress;
      }

      datas.shipping.phone = res.shipping.phone ? res.shipping.phone : "";

      datas.shipping.closingDates.monday = res.shipping.closingDates.monday;
      datas.shipping.closingDates.saturday = res.shipping.closingDates.saturday;
      datas.packaging.partNb = res.partNbr ? res.partNbr : 1;
      datas.packaging.instructions = false;
      datas.packaging.address = "";
      datas.packaging.complementaryPart = false;

      datas.finish.mainContact = res.mainContact ? res.mainContact : "";
      datas.finish.billingContact = res.billingContact
        ? res.billingContact
        : "";
      datas.finish.technicalContact = res.technicalContact
        ? res.technicalContact
        : "";
    };

    /**
     * Add a contact to the default data
     *
     * @param {*} contact
     * @param {*} datas
     */
    const addContactValues = (contact, datas) => {
      const contactFormated = {
        id: contact.id,
        email: contact.email,
        firstname: contact.firstname,
        lastname: contact.lastname,
        phone: contact.phone,
      };
      switch (contact.type) {
        case AppConstant.typeContactBilling:
          datas.finish.billingContact = contactFormated;
          break;
        case AppConstant.typeContactMain:
          datas.finish.mainContact = contactFormated;
          break;
        case AppConstant.typeContactTech:
        default:
          datas.finish.technicalContact = contactFormated;
          break;
      }
    };

    let datas = cloneDeep(defaultValues);

    // Load thirdparty values for shipping from account informations
    const userData = user.data;
    if (userData.thirdparty) {
      const thirdpartyAddress = userData.thirdparty.address;
      datas.shipping.company = userData.thirdparty.name;
      datas.shipping.address = { ...emptyAddress };
      datas.shipping.address.fullAddress = thirdpartyAddress.fullAddress;
      datas.shipping.address.city = thirdpartyAddress.city;
      datas.shipping.address.street = thirdpartyAddress.street;
      datas.shipping.address.zipCode = thirdpartyAddress.zipCode;
      datas.shipping.address.countryCode = thirdpartyAddress.countryCode;

      datas.shipping.phone =
        userData.thirdparty.phone !== null ? userData.thirdparty.phone : "";

      // Load contacts
      const contactList = userData.thirdparty.contactList;
      if (contactList) {
        for (let i = 0; i < contactList.length; i++) {
          addContactValues(contactList[i], datas);
        }
      }
    }

    let now = moment();
    // If pickup date is past, we set it to now
    if (datas.shipping.pickupDate <= now) {
      datas.shipping.pickupDate = now;
    }
    // And if pickup date is today after 12h, we set it to the next day
    if (
      datas.shipping.pickupDate.isSame(now, "day") &&
      datas.shipping.pickupDate.hour() >= 12
    ) {
      datas.shipping.pickupDate = now.add(1, "days");
    }

    if (
      user.data.individual &&
      datas.shipping.method !== AppConstant.shippingMethodPersonal
    ) {
      datas.shipping.method = AppConstant.shippingMethodPersonal;
    }

    // We want to create a saved inter
    if (idParam !== null) {
      // Load inter parameters
      interventionService
        .getSavedInterventionDetails(idParam)
        .then((res) => {
          formatData(res, datas);
          setValues(datas);
        })
        .catch((err) => {
          // If intervention not in draf redirect to dashboard (security check)
          if (err.response.status === 403) {
            window.location.href = "/";
          }
        });
    } else {
      setValues(datas);
    }
  }, [idParam, user]);

  /**
   * Update the value of a field
   *
   * @param {string} family     Field family to update (example: 'part')
   * @param {string} field      Field name to update (example: 'frequency')
   * @param {string} value      Field value to set
   */
  const updateValue = (family, field, value) => {
    let newValues = { ...values }; // Copy actual values
    newValues[family][field] = value;
    setValues(newValues);
  };

  const handleCancelClick = () => {
    setDisplayDialog(true);
  };

  const handleCancel = () => {
    setDisplayDialog(false);
  };

  const handleConfirm = () => {
    setDisplayDialog(false);
    setValues(cloneDeep(defaultValues));
    history.goBack();
  };

  const handleSave = async () => {
    try {
      const interData = formateInterventionData(
        values,
        AppConstant.interStatusTransmited
      );

      interData.shipping.pickupDate = moment(
        interData.shipping.pickupDate
      ).unix();

      let res = await interventionService.postSaveIntervention(interData);

      if (res.id) {
        // Upload pictures
        for (let i = 0; i < interData.pictures.length; i++) {
          if (!values.part.oldpictures.includes(interData.pictures[i])) {
            interventionService
              .uploadDocument(res.id, interData.pictures[i])
              .catch((err, uploadErr) => {
                console.error(err);
                uploadErr++;
              });
          }
        }
        // Delete pictures
        if (
          values.part.oldpictures !== [] &&
          values.part.pictures !== values.part.oldpictures
        ) {
          for (let i = 0; i < values.part.oldpictures.length; i++) {
            if (!interData.pictures.includes(values.part.oldpictures[i])) {
              interventionService
                .deleteDocument(res.id, values.part.oldpictures[i])
                .catch((err, uploadErr) => {
                  console.error(err);
                  uploadErr++;
                });
            }
          }
        }
      }

      // Fetch me to get updated contact list and intervention numbers
      fetchConnectedUser();

      // Display feedback and redirect
      alert.show("", {
        title: t("SaveInterSuccess"),
        close: () => {
          alert.remove(alert);
        },
        type: "success",
      });
      history.push("/");
    } catch (err) {
      console.error(err);
      alert.show(t("CreateInterErrorDesc"), {
        title: t("CreateInterError"),
        close: () => {
          alert.remove(alert);
        },
        type: "error",
      });
    }
  };

  return (
    <>
      <Header
        focus={false}
        variant="primary"
        title={t("NewIntervention")}
        titleIcon={<PersonOutlineIcon />}
        titleDesc=""
      >
        {menu.onSmartphone ? (
          <HeaderActions>
            <CircleButton
              className={classes.leftBtn}
              onClick={handleCancelClick}
            >
              <CloseIcon />
            </CircleButton>
            <CircleButton
              onClick={handleSave}
              className={classes.rightBtn}
              variant="contained"
            >
              <SaveIcon />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              className={classes.leftBtn}
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={handleCancelClick}
            >
              {t("Cancel")}
            </Button>
            <Button
              className={classes.rightBtn}
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </HeaderActions>
        )}
      </Header>
      <Content>
        <NewInterventionForm values={values} updateValue={updateValue} />
      </Content>
      {displayDialog && (
        <Dialog
          open={displayDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Box display="flex" alignItems="center">
              <WarningIcon color="primary" style={{ marginRight: "5px" }} />
              {t("ConfirmCancelNewInterTitle")}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("ConfirmCancelNewInterDesc")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t("NotConfirmCancelNewInter")}
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              endIcon={<CancelIcon />}
            >
              {t("ConfirmCancelNewInter")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NewIntervention;
