import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-root": {
      borderRadius: theme.spacing(1) + 2,
      backgroundColor: theme.palette.grey[50],

      "&::before": {
        borderBottom: "none",
      },
      "&:hover:before": {
        borderBottom: "none",
      },
      // Border bottom of the input
      "&.MuiFilledInput-underline:after": {
        width: `calc(100% - ${(theme.spacing(1) + 2) * 2}px)`, // We substract border-radius of each corner
        left: theme.spacing(1) + 2, // We center the line
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      "&.Mui-error.MuiFilledInput-underline:after": {
        borderColor: theme.palette.error.main,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.primary,
      },
    },
    "& label": {
      color: theme.palette.primary.main,
      "&.Mui-disabled": {
        color: theme.palette.primary.main,
      },
    },
    "& fieldset": {
      borderWidth: "0",
    },
  },
}));

const CotroliaDatePicker = (props) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        className={`${classes.input} ${props.className ? props.className : ""}`}
        variant="inline"
        inputVariant="filled"
        format="DD/MM/YYYY"
        invalidDateMessage={t("InvalidDate")}
        minDateMessage={t("MinDateError", {
          minDate: moment(props.minDate).format("L"),
        })}
        disableToolbar
        autoOk
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CotroliaDatePicker;
